import axios from 'axios';
import UserModel from '@/models/User';
import { EventBus } from '@/plugins/events';
import pick from 'lodash/pick';
import find from 'lodash/find';

// initial state
const state = () => ({
  data: null,
  isExistsFlow: false,
  selectedProfile: null,
  onlyStartups: false,
  linkedin: {
    expiresAt: null,
    accessToken: null
  }
});

const USER_SET_LINKEDIN_ACCESS_TOKEN = 'USER_SET_LINKEDIN_ACCESS_TOKEN';
const USER_RESET_LINKEDIN = 'USER_RESET_LINKEDIN';

// getters
const getters = {
  uiFeatures(state) {
    return state.data.uiFeatures || [];
  },
  isLoggedIn(state) {
    return !!state.data;
  },
  data(state) {
    return state.data;
  },
  onlyStartups(state) {
    return state.onlyStartups;
  },
  allProfiles(state) {
    if (!state.data) {
      return [];
    }

    let profiles = [];

    state.data.companies.map((company) => {
      const countries = company.countries.map((item) => item.countryCode).join(', ');

      profiles.push({
        type: 'company',
        id: company.id,
        key: company.key,
        name: company.name,
        link: company.adminUrl,
        imageLink: company.logoImage && company.logoImage.thumbnailSizeUrl,
        additional: countries,
        typeLabel: 'Startup',
        description: `Startup management and recruitment`,
        isDraft: company.status === 'DRAFT',
        platform: company.platform,
        company
      });
    });

    state.data.investors.map((investor) => {
      profiles.push({
        type: `investor`,
        id: investor.id,
        key: investor.key,
        name: investor.name,
        imageLink: investor.logoImage ? investor.logoImage.thumbnailSizeUrl : null,
        link: investor.adminUrl,
        additional: investor.countryCodes.join(', '),
        typeLabel: 'Investor',
        description: `Investment and funding`,
        isDraft: investor.status === 'DRAFT',
        platform: investor.platform
      });
    });

    if (state.data.applicant.enabled) {
      profiles.push({
        type: 'applicant',
        key: null,
        name: state.data.fullName,
        imageLink: state.data.imageUrl,
        link: '/client/applicant',
        typeLabel: 'Talent',
        description: `Talent`
      });
    }

    return profiles;
  },
  profiles(state, getters, rootState) {
    return getters.allProfiles.filter(
      (item) => !item.platform || item.platform[rootState.platform]
    );
  },
  startupProfiles(state, getters, rootState) {
    return getters.allProfiles
      .filter((item) => !item.platform || item.platform[rootState.platform])
      .filter((item) => item.type === 'company');
  },
  hasStartupProfiles(state, getters) {
    return getters.startupProfiles.length > 0;
  },
  hasProfiles(state, getters) {
    return getters.profiles.length > 0;
  },
  hasAnyProfile(state, getters) {
    return getters.allProfiles.length > 0;
  },
  hasMultipleProfiles(state, getters) {
    return getters.profiles.length > 1;
  },
  hasSelectedProfile(state) {
    return Boolean(state.selectedProfile);
  },
  isValidProfile(state, getters) {
    return (profile) => {
      return find(getters.profiles, pick(profile, ['key', 'type']));
    };
  },
  currentProfile(state, getters) {
    const profile = state.selectedProfile;

    let currentProfile;

    if (profile) {
      currentProfile = find(getters.profiles, {
        key: profile.key,
        type: profile.type
      });
    }

    if (!currentProfile && getters.isLoggedIn) {
      const user = getters.data;
      currentProfile = {
        type: 'user',
        key: user.id,
        name: user.fullName,
        link: '/settings',
        imageLink: user.imageUrl
      };
    }

    return currentProfile;
  }
};

// actions
const actions = {
  async checkAuth({ getters, dispatch, commit }) {
    const isLoggedInBefore = getters.isLoggedIn;
    const user = getters.data;

    try {
      /**
       * @TODO
       *
       * workaround we are using axios directly here because we want to disable loading bar when doing this check.
       * but currently there is a bug
       *  https://github.com/nuxt-community/axios-module/issues/258
       *  https://github.com/axios/axios/pull/2207#issuecomment-529163362
       *
       * which has not yet been merged. When we can update we can simply replace this call with `this.$axios.get(..., { progress: false })`
       *
       * this works because `checkAuth` is only issued from the client.
       */
      const { data } = await axios.get('/api/users/me');
      commit('setData', new UserModel(data));

      if (isLoggedInBefore && user.id !== data.id) {
        // user logged in as a different user on another tab.

        // refresh current page. (https://stackoverflow.com/questions/41301099/do-we-have-router-reload-in-vue-router)
        this.$router.go();
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        // user does no longer have a valid session, force logout.
        if (isLoggedInBefore) {
          dispatch('logout');

          // https://github.com/nuxt/nuxt.js/issues/1298
          this.$router.replace('/');
        } else {
          commit('resetData');
        }
      }
    }
  },
  async load({ commit, dispatch }) {
    try {
      const { data } = await this.$axios.get('users/me');
      commit('setData', new UserModel(data));

      // if no profile selected, try to load one.
      // for ex. when a user creates a company (draft or submits)
      // we issue a user/load, which then will auto select the newly created company.
      if (!state.selectedProfile) {
        dispatch('loadProfile');
      }
    } catch (err) {}
  },
  async login({ commit, dispatch }, form) {
    const { data } = await this.$axios.post('users/sign-in', form);
    const user = new UserModel(data);

    // console.log('user: logged-in', user)

    commit('resetData');
    commit('setIsExistsFlow', !!form.exists);
    commit('setData', user);

    dispatch('loadProfile');
  },
  connectLinkedin({ state, commit }) {
    return new Promise((resolve, reject) => {
      const hasValidAccessToken =
        state.linkedin.accessToken && new Date().getTime() < state.linkedin.expiresAt;
      if (hasValidAccessToken) {
        resolve({
          didAuthorize: true,
          accessToken: state.linkedin.accessToken
        });

        return;
      }

      EventBus.$off('linkedin:auth');
      EventBus.$once('linkedin:auth', function(event) {
        clearInterval(detectWindowCloseInterval);

        if (event.payload.error) {
          if (
            event.payload.error === 'user_cancelled_login' ||
            event.payload.error === 'user_cancelled_authorize'
          ) {
            resolve({
              didAuthorize: false
            });
          } else {
            reject(
              new Error({
                message: event.payload.errorDescription
              })
            );
          }

          return;
        }

        commit(USER_SET_LINKEDIN_ACCESS_TOKEN, {
          accessToken: event.payload.access_token,
          expiresIn: event.payload.expires_in
        });

        resolve({
          didAuthorize: true,
          accessToken: event.payload.access_token,
          expiresIn: event.payload.expires_in
        });
      });

      const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.LINKEDIN_REDIRECT_URL}&scope=r_liteprofile,r_emailaddress`;
      const windowHandler = window.open(url, 'linkedin', 'width=800,height=650,scrollbars=no');
      const detectWindowCloseInterval = setInterval(function() {
        if (windowHandler.closed) {
          clearInterval(detectWindowCloseInterval);

          resolve({
            didAuthorize: false
          });
        }
      }, 250);
    });
  },
  async authorizeWithLinkedin({ dispatch, commit }, { allowRegistration = true }) {
    const result = await dispatch('connectLinkedin');
    if (!result.didAuthorize) {
      return {
        authorized: false,
        userExists: false
      };
    }

    const response = await this.$axios.post('/users/authorize-with-linkedin', {
      accessToken: result.accessToken,
      allowRegistration
    });

    if (response.data.user) {
      if (response.data.found) {
        // simple login found existing
      } else if (response.data.created) {
        // created new user
        this.$gtm.push({ event: 'fb', fbEvent: 'ProfileRegistration' });
        this.$gtm.push({
          event: 'ga.event',
          category: 'Profile',
          action: 'Profile Registration'
        });
      }

      commit('resetData');
      commit('setData', new UserModel(response.data.user));
      dispatch('loadProfile');

      return {
        authorized: result.didAuthorize,
        userExists: true
      };
    }

    // user authorized with linkedin, but not existing user existed, and was not allowed to create one.
    return {
      authorized: result.didAuthorize,
      userExists: false
    };
  },
  forgotPassword(context, payload) {
    return this.$axios.post('/users/forgot-password', {
      email: payload.email
    });
  },
  async resetPassword({ commit, dispatch }, payload) {
    // return this.$axios.post('/users/reset-password', {
    //   password: payload.password,
    //   resetKey: payload.token
    // })

    const { data } = await this.$axios.post('/users/reset-password', {
      password: payload.password,
      resetKey: payload.token
    });
    const user = new UserModel(data);

    // console.log('user: logged-in', user)

    commit('resetData');
    // commit('setIsExistsFlow', !!form.exists)
    commit('setData', user);

    if (payload.key) {
      dispatch('loadCompanyProfile', { key: payload.key });
    } else {
      dispatch('loadProfile');
    }
    // dispatch('loadProfile')
    return { data };

    // console.log('getters.currentProfile', getters.currentProfile());
  },
  async verifyEmail({ commit }, payload) {
    await this.$axios.post('/users/verify-email', {
      emailVerificationKey: payload.token
    });

    commit('update', {
      isEmailVerified: true
    });
  },
  async signUp({ commit, dispatch }, user) {
    try {
      const { data } = await this.$axios.post('/users/sign-up', user);

      this.$gtm.push({ event: 'fb', fbEvent: 'ProfileRegistration' });
      this.$gtm.push({
        event: 'ga.event',
        category: 'Profile',
        action: 'Profile Registration'
      });

      commit('resetData');
      commit('setData', new UserModel(data));

      dispatch('loadProfile');
    } catch (err) {
      localStorage.removeItem('user');
      localStorage.removeItem('isLoggedIn');

      throw err;
    }
  },
  async logout({ commit }) {
    commit('resetData');
    await this.$axios.post('users/sign-out');
  },
  loadCompanyProfile({ commit, /* state,*/ getters }, payload) {
    // console.log('loadCompanyProfile getters.profiles', getters.profiles)
    // console.log('loadCompanyProfile payload', payload)

    if (getters.profiles && getters.profiles.length) {
      getters.profiles.forEach((profile) => {
        if (profile.key === payload.key) {
          commit('setProfile', profile);
        }
      });
    }
    // only one `profile` just auto-select it.
    // if (getters.profiles.length === 1) {
    //   commit('setProfile', getters.profiles[0])
    //   return
    // }

    // if user is logged in, check if we have prefered profile stored in user data.
    // if (state.data) {
    //   const profile = state.data.profile
    //   if (profile && getters.isValidProfile(profile)) {
    //     commit('setProfile', JSON.parse(profile))
    //     return
    //   }
    // }

    // check if we have it stored in cookie.
    // const profile = this.$cookies.get('user.profile')
    // if (typeof profile === 'object' && getters.isValidProfile(profile)) {
    //   commit('setProfile', profile)
    // }
  },
  loadProfile({ commit, state, getters }) {
    // only one `profile` just auto-select it.
    if (getters.profiles.length === 1) {
      commit('setProfile', getters.profiles[0]);
      return;
    }

    // if user is logged in, check if we have prefered profile stored in user data.
    if (state.data) {
      const profile = state.data.profile;
      if (profile && getters.isValidProfile(profile)) {
        commit('setProfile', JSON.parse(profile));
        return;
      }
    }

    // check if we have it stored in cookie.
    const profile = this.$cookies.get('user.profile');
    if (typeof profile === 'object' && getters.isValidProfile(profile)) {
      commit('setProfile', profile);
    }
  },
  switchProfile({ commit }, { profile, save = false }) {
    commit('setProfile', profile);

    return this.$axios.put('users/me', {
      profile: save ? JSON.stringify(profile) : null
    });
  },
  async createApplicantProfile({ commit }) {
    const data = await this.$axios.$post('applicant');

    commit('update', data);
    commit('setProfile', {
      type: 'applicant',
      key: null
    });
  },
  setOnlyStartups({ commit }, payload) {
    // console.log('setOnlyStartups action', payload);
    commit('setOnlyStartups', payload);
  }
};

// mutations
const mutations = {
  hydrate(state) {
    if (state.data) {
      state.data = new UserModel(state.data);
    }
  },
  setData(state, data) {
    // reset profile on user change
    state.data = data;
  },
  setIsExistsFlow(state, value) {
    state.isExistsFlow = value;
  },
  update(state, data) {
    state.data.update(data);
  },
  setOnlyStartups(state, value) {
    // console.log('setOnlyStartups mutation', value);
    state.onlyStartups = value;
  },
  resetData(state) {
    state.selectedProfile = null;
    state.data = null;

    this.$cookies.remove('user.profile');
  },
  [USER_SET_LINKEDIN_ACCESS_TOKEN](state, { expiresIn, accessToken }) {
    state.linkedin = {
      expiresAt: new Date().getTime() + (expiresIn - 60000),
      accessToken
    };
  },
  [USER_RESET_LINKEDIN](state) {
    state.linkedin = {
      expiresAt: null,
      accessToken: null
    };
  },
  setProfile(state, profile) {
    // console.log('set-profile', profile.key)

    state.selectedProfile = pick(profile, ['key', 'type']);

    this.$cookies.set('user.profile', state.selectedProfile);
  },
  updateProfile(state, { profile, data }) {
    state.data[profile.type === 'company' ? 'companies' : 'investors'].forEach((item) => {
      if (item.id === data.id) {
        item.update(data);
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
